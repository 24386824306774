<template>
  <div>
    <div class="section-title section-title_app">
      <h3>REPORTE DE ORDENES CON RESULTADOS EN FISICO</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <div class="column is-2">
            <b-field label="Fecha inicio"
                     label-position="on-border">
              <b-datepicker placeholder="2023"
                            v-model="date_order_start"
                            :max-date="maxDatetime"
                            nearby-selectable-month-days
                            :min-date="minDatetime">

              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Fecha fin"
                     label-position="on-border">
              <b-datepicker placeholder="2023"
                            v-model="date_order_end"
                            :max-date="maxDatetime"
                            nearby-selectable-month-days
                            :min-date="minDatetime">

              </b-datepicker>
            </b-field>
          </div>

          <div class="column is-1">
            <b-tooltip label="Exportar excel">
              <b-button size="is-small" type="is-primary" @click="handleExport">Exportar excel</b-button>
            </b-tooltip>
          </div>
        </div>
      </div>

      <div class="column is-12 has-background-white">
        <iframe class="lapi__iframe" :src="reportUrl"></iframe>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "PhysicalResults",
  data() {
    const maxYear = new Date()
    maxYear.setFullYear(maxYear.getFullYear() + 1, 0, 0)
    const minYear = new Date()
    minYear.setFullYear(minYear.getFullYear() - 3, 12, 1)
    return {
      date: new Date(),
      date_order_start: new Date(),
      date_order_end: new Date(),
      maxDatetime: maxYear,
      minDatetime: minYear,
      order: '',
      data: [],
      loading: true,
      branch_office: '',
      total: 0,
      showModal: false,
      perPage: 50,
      currentPage: 1,
    }
  },
  computed: {
    reportUrl() {
      const url = new URL(`${process.env.VUE_APP_API_URL}/reports/branch/physical-results/${this.$auth.user().active_branch_office}/${this.transformDate}/${this.transformDate2}`);
      return url.href;
    },
    transformDate(){
      const new_date= `${this.date_order_start.getFullYear()}-${(this.date_order_start.getMonth()+1).toString().padStart(2, '0')}-${this.date_order_start.getDate().toString().padStart(2, '0')}`
      return new_date;
    },
    transformDate2(){
      const new_date= `${this.date_order_end.getFullYear()}-${(this.date_order_end.getMonth()+1).toString().padStart(2, '0')}-${this.date_order_end.getDate().toString().padStart(2, '0')}`
      return new_date;
    },
  },
  methods: {
    handleExport(){
      this.$loader.show();
      this.$http.get(`${process.env.VUE_APP_API_URL}/reports/branch/physical-results/${this.$auth.user().active_branch_office}/${this.transformDate}/${this.transformDate2}?type=xlsx`,{responseType: 'blob'}).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data],
            { type: response.data.type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',`Resultados_fisicos_${this.$auth.user().active_branch_office}_${this.transformDate}_${this.transformDate2}.xlsx`)
        document.body.appendChild(link);
        link.click();
      }).finally(() => {
        this.$loader.hide();
      })
    },
  }
}
</script>
<style scoped>
.lapi__iframe{
  width: 100%;
  height: 80vh;
}
</style>
